import {
  BrowserRouter as Router,
  Routes,
  Route,
  //Link
} from "react-router-dom";
import { Legal } from "./pages/Legal";
import Beta from "./pages/Beta";
import FramerKhair from "./pages/FramerKhair";

function App() {
  return (
    <div className="App">
       <Router>
          <Routes>
          <Route path="/" element={<FramerKhair/>}/>
          <Route path="/legal" element={<Legal/>}/>
          <Route path="/beta" element={<Beta/>}/>
          </Routes>
        </Router>
    </div>
  );
}

export default App;
